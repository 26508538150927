import React, { useState, useEffect } from "react"
import styled from "styled-components"
import "maplibre-gl/dist/maplibre-gl.css"
import ReactMapboxGl, { ZoomControl, Marker, Cluster, Popup } from "react-mapbox-gl"
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"

import MarkerIcon from "../../../static/images/icons/marker.svg"
import MarkerClusterIcon from "../../../static/images/icons/markerCluster.svg"
import * as theme from "theme"

let MapBox = false

if (typeof window !== `undefined` && process.env.GATSBY_MAPBOX) {
  MapBox = ReactMapboxGl({
    accessToken: process.env.GATSBY_MAPBOX
  })
}

const Map = ({ retailers }) => {
  const [map, setMap] = useState(null)
  const [popup, setPopup] = useState({ longitude: 0, latitude: 0, text: null })

  useEffect(() => {
    if (map) {
      fitBounds()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  const onLoad = map => setMap(map)

  const fitBounds = () => {
    // Get min and max values
    const maxLat = Math.max.apply(
      Math,
      retailers.map(o => o.Retailer.googleMap.latitude)
    )
    const minLat = Math.min.apply(
      Math,
      retailers.map(o => o.Retailer.googleMap.latitude)
    )
    const maxLng = Math.max.apply(
      Math,
      retailers.map(o => o.Retailer.googleMap.longitude)
    )
    const minLng = Math.min.apply(
      Math,
      retailers.map(o => o.Retailer.googleMap.longitude)
    )

    if (!!maxLat && !!minLat && !!maxLng && !!minLng) {
      map.fitBounds(
        [
          [maxLng, maxLat],
          [minLng, minLat]
        ],
        {
          padding: { top: 10, bottom: 10, left: 10, right: 10 }
        }
      )
    }
  }

  const handleClosePopup = () => setPopup({ longitude: 0, latitude: 0, text: null })

  const clusterMarker = coordinates => (
    <Marker key={JSON.stringify(coordinates)} coordinates={coordinates}>
      <StyledMarkerClusterIcon />
    </Marker>
  )

  if (typeof window !== "undefined") {
    return (
      <>
        {MapBox && (
          <MapContainer>
            <MapBox
              // eslint-disable-next-line
              style="mapbox://styles/mapbox/streets-v8"
              onStyleLoad={onLoad}
              maxZoom={18}
              movingMethod="jumpTo"
              onClick={handleClosePopup}
              maxBounds={[
                [-160, 20],
                [-50, 70]
              ]}
            >
              <ZoomControl />

              {retailers && (
                <Cluster zoomOnClick ClusterMarkerFactory={clusterMarker}>
                  {retailers.map(o => {
                    return (
                      <Marker
                        key={o.id}
                        coordinates={[o.Retailer.googleMap.longitude, o.Retailer.googleMap.latitude]}
                        onClick={() =>
                          setPopup({
                            longitude: o.Retailer.googleMap.longitude,
                            latitude: o.Retailer.googleMap.latitude,
                            text: (
                              <>
                                <Typography variant="h4" children={Parser(o.title)} />
                                <div>
                                  <Typography variant="caption" children={o.Retailer.googleMap.streetAddress} />
                                </div>
                                {o.Retailer.phone && <Typography variant="caption" children={o.Retailer.phone} />}
                              </>
                            )
                          })
                        }
                      >
                        <StyledMarkerIcon />
                      </Marker>
                    )
                  })}
                </Cluster>
              )}

              {popup.text && (
                <Popup
                  coordinates={[popup.longitude, popup.latitude]}
                  offset={{
                    "bottom-left": [12, -38],
                    bottom: [0, -38],
                    "bottom-right": [-12, -38]
                  }}
                >
                  {popup.text}
                </Popup>
              )}
            </MapBox>
          </MapContainer>
        )}
      </>
    )
  }

  return null
}

const MapContainer = styled.div`
  position: relative;
  height: 360px;
  width: 100%;

  .mapboxgl-map {
    height: 300px;
  }

  @media (min-width: 960px) {
    height: 460px;
    order: 2;

    .mapboxgl-map {
      height: 400px;
    }
  }
`

const StyledMarkerIcon = styled(MarkerIcon)`
  width: 36px !important;
  height: 36px !important;
  cursor: pointer;

  path {
    fill: ${props => (props.variant === "location" ? theme.colors.secondary : theme.colors.primary)};
  }
`

const StyledMarkerClusterIcon = styled(MarkerClusterIcon)`
  width: 36px !important;
  height: 36px !important;
  cursor: pointer;

  path {
    fill: ${theme.colors.primary};
  }
`

export default Map
