import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// import app components
import Textarea from "components/textarea"
import Spacer from "components/spacer"
import SidebarContent from "components/sidebarContent"
import LinkBoxes from "components/footer/LinkBoxes"
import Map from "components/map"
import { getUrlPath } from "utils/getUrlPath"

export default function singleMembers(props) {
  const {
    data: {
      wpMember: {
        id,
        title,
        uri,
        content,
        Members: { smallMemberLogo }
      },
      allWpRetailer: { nodes: retailers }
    }
  } = props

  // Filter out all retailers of member which have a location attached
  const filteredRetailers = retailers.filter(
    (o) =>
      o?.Retailer?.premiumRetailer?.id === id && o?.Retailer?.googleMap?.longitude && o?.Retailer?.googleMap?.latitude
  )

  return (
    <>
      <SidebarContent image={null} title={title} slug={getUrlPath(uri)} hideSidebarOnMobile>
        {smallMemberLogo?.sourceUrl && (
          <ImageContainer>
            <img src={smallMemberLogo?.sourceUrl} alt="Logo" />
          </ImageContainer>
        )}

        <Spacer pb={30}>
          <StyledTextarea content={content} />
        </Spacer>

        {filteredRetailers.length > 0 && <Map retailers={filteredRetailers} />}
      </SidebarContent>

      <LinkBoxes />
    </>
  )
}

const ImageContainer = styled.div`
  width: 100%;
  height: 120px;
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 640px) {
    max-width: 300px;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const StyledTextarea = styled(Textarea)`
  clear: unset;
`

export const CollectionQuery = graphql`
  query SingleMember($id: String!) {
    wpMember(id: { eq: $id }) {
      id
      title
      uri
      content
      language {
        slug
      }
      Members {
        smallMemberLogo {
          sourceUrl
        }
        fieldGroupName
        excludeFromOrganicSearchResults
      }
    }

    allWpRetailer {
      nodes {
        id
        Retailer {
          email
          phone
          premiumRetailer {
            ... on WpMember {
              id
            }
          }
          specifications
          websiteUrl
          googleMap {
            latitude
            longitude
            streetAddress
          }
        }
        title
      }
    }
  }
`
